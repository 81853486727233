<template>
  <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
    <div class="add-user">
      <div class="container-fluid">
        <button @click="exportPdf" type="button" style="width: 13%;" class="col-2 p-1 btn btn-primary">
          <i class="fa fa-download" aria-hidden="true"></i>
          健診結果票
          <i style="font-size:16px" class="fas icon-loading rotate-loading">&#xf2f1;</i>
        </button>
        <div class="tab" id="detail-import-diagnostic-data">
          <ul class="nav nav-pills mb-5 pl-0 mt-5" id="pills-tab" role="tablist">
            <li class="nav-item border" role="presentation">
              <button class="nav-link active rounded-0" id="eligible-person-information-tab" data-bs-toggle="pill"
                      data-bs-target="#eligible-person-information" type="button" role="tab"
                      aria-controls="eligible-person-information" aria-selected="true">
                対象者情報
              </button>
            </li>
            <li class="nav-item border" role="presentation">
              <button class="nav-link rounded-0" id="health-check-result-tab" data-bs-toggle="pill"
                      data-bs-target="#health-check-result" type="button" role="tab" aria-controls="health-check-result"
                      aria-selected="false">
                健診結果
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="eligible-person-information" role="tabpanel"
                 aria-labelledby="eligible-person-information-tab">
              <div class="col-12 border tab-eligible-person-information" v-if="dataTabA">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <td class="bg-gray width-180px">対象者ID</td>
                    <td class="width-250px"> <span v-if="dataTabA.id">{{ String(dataTabA.id).padStart(10, "0") }}</span>
                    </td>
                    <td class="bg-gray width-180px">保健指導区分</td>
                    <td class="width-250px">{{ dataTabA.health_guidance_div }}</td>
                    <td class="bg-gray width-180px">利用券整理番号</td>
                    <td class="width-250px">
                        {{ dataTabA.use_ticket_ref_number }}
                        <span v-if="dataTabA.use_ticket_effective_date">({{ dataTabA.use_ticket_effective_date }})</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-gray width-180px">氏名カナ</td>
                    <td class="width-250px">{{ dataTabA.patient_name_kana }}</td>
                    <td class="bg-gray width-180px">氏名</td>
                    <td class="width-250px">{{ dataTabA.patient_name }}</td>
                    <td class="bg-gray width-180px">生年月日 / 性別</td>
                    <td class="width-250px">
                      {{ dataTabA.patient_birthday }}
                      <span v-if="dataTabA.patient_gender"> / </span>
                      {{ dataTabA.patient_gender }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-gray width-180px">保険者</td>
                    <td class="width-250px">
                    <span v-if="dataTabA.client_organizations?.client_organ_code">
                      [{{ dataTabA.client_organizations?.client_organ_code }}]
                      {{ dataTabA.client_organizations?.client_organ_name }}
                    </span>
                    </td>
                    <td class="bg-gray width-180px">契約区分</td>
                    <td class="width-250px">{{ dataTabA.health_guidance_contract_div }}</td>
                    <td class="bg-gray width-180px">本人家族区分</td>
                    <td class="width-250px">{{ dataTabA.person_familiy_div }}</td>
                  </tr>
                  <tr>
                    <td class="bg-gray width-180px">保険証番号</td>
                    <td class="width-250px">{{ dataTabA.insured_card_number }}</td>
                    <td class="bg-gray width-180px">保険証記号</td>
                    <td class="width-250px">{{ dataTabA.insured_card_symbol }}</td>
                    <td class="bg-gray width-180px">保険証枝番</td>
                    <td class="width-250px">{{ dataTabA.insured_card_branch_code }}</td>
                  </tr>
                  <tr>
                    <td class="bg-gray width-180px">郵便番号</td>
                    <td class="width-250px">{{ dataTabA.patient_postal_code }}</td>
                    <td class="bg-gray width-180px">住所</td>
                    <td colspan="3">{{ dataTabA.patient_addr_01 }}</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="pt-5"></td>
                  </tr>
                  <tr>
                    <td class="bg-gray width-180px">受付状況</td>
                    <td class="width-250px">
                      <span v-if="dataTabA.health_guidance_no_target == 1">保健指導対象外</span>
                      <span v-if="
                      dataTabA.health_guidance_receptions?.length < 1 &&
                      dataTabA.health_guidance_no_target == 0
                    ">未受付</span>
                      <span v-if="
                      dataTabA.health_guidance_receptions?.length > 0 &&
                      dataTabA.health_guidance_no_target == 0
                    ">受付済</span>
                    </td>
                    <td class="bg-gray width-180px">支援状況</td>
                    <td class="width-250px">
                      <span v-if="dataTabA.health_guidance_no_target == 1">ー</span>
                      <span v-else>{{ dataTabAStatus.codeSupportStatus }}</span>
                    </td>
                    <td class="bg-gray width-180px">支援状況詳細</td>
                    <td class="width-250px">
                      <span v-if="dataTabA.health_guidance_no_target == 1">ー</span>
                      <span v-else>{{ dataTabAStatus.codeSupportExecStatus }}
                      {{ dataTabAStatus.codeSupportProgressStatus }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-2 pt-0 pb-0">
                  <div class="mb-3 pl-0 col-8 form-check">
                    <label class="form-check-label" for="exampleCheck1">保健指導対象外</label>
                    <input type="checkbox"
                      :disabled="!checkViewOrEdit || dataTabA.health_guidance_receptions.length > 0"
                      v-if="dataTabA.health_guidance_no_target == 1" checked value="1"
                      v-model="dataEdit.health_guidance_no_target" class="form-check-input float-right"
                      id="exampleCheck1" />

                    <input type="checkbox"
                      :disabled="!checkViewOrEdit || dataTabA.health_guidance_receptions.length > 0"
                      v-if="dataTabA.health_guidance_no_target == 0" value="0"
                      v-model="dataEdit.health_guidance_no_target" class="form-check-input float-right"
                      id="exampleCheck1" />
                  </div>
                </div>
                <div class="col-10">
                  <div>
                    <div class="float-right">
                      <div class="d-flex">
                        <div style="width:300px">登録情報</div>
                        <div style="width:300px">更新情報</div>
                      </div>
                      <div class="d-flex">
                        <Sheet width="300" height="40" class="p-2">
                          {{ formatDate(dataTabA.created_at) }} by
                          {{ dataTabA.created_by ? dataTabA.created_by.user_name : null }}
                        </Sheet>
                        <Sheet width="300" height="40" class="p-2">
                          {{ formatDate(dataTabA.updated_at) }} by
                          {{ dataTabA.updated_by ? dataTabA.updated_by.user_name : null }}
                        </Sheet>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pl-0">
                <div class="row">
                  <div class="col-3">
                    電話連絡先(携帯)
                    <input type="text" :disabled="!checkViewOrEdit" v-model="dataEdit.patient_tel_01"
                      @blur="validate('patient_tel_01')" @keyup="validate('patient_tel_01')" class="form-control" />
                    <span class="text-danger">{{ errors.patient_tel_01 }}</span>
                  </div>
                  <div class="col-3">
                    電話連絡先(自宅)
                    <input type="text" :disabled="!checkViewOrEdit" v-model="dataEdit.patient_tel_02"
                      @blur="validate('patient_tel_02')" @keyup="validate('patient_tel_02')" class="form-control" />
                    <span class="text-danger">{{ errors.patient_tel_02 }}</span>
                  </div>
                  <div class="col-3">
                    電話連絡先(職場)
                    <input type="text" :disabled="!checkViewOrEdit" v-model="dataEdit.patient_tel_03"
                      @blur="validate('patient_tel_03')" @keyup="validate('patient_tel_03')" class="form-control" />
                    <span class="text-danger">{{ errors.patient_tel_03 }}</span>
                  </div>
                  <div class="col-3">
                    電話連絡先(その他)
                    <input type="text" :disabled="!checkViewOrEdit" v-model="dataEdit.patient_tel_04"
                      @blur="validate('patient_tel_04')" @keyup="validate('patient_tel_04')" class="form-control" />
                    <span class="text-danger">{{ errors.patient_tel_04 }}</span>
                  </div>
                  </div>
                </div>

                <div class="col-12 pl-0">
                <div class="row">
                  <div class="col-6">
                    メールアドレス
                    <input type="text" :disabled="!checkViewOrEdit" v-model="dataEdit.patient_mail_01"
                      @blur="validate('patient_mail_01')" @keyup="validate('patient_mail_01')" class="form-control" />
                    <span class="text-danger">{{ errors.patient_mail_01 }}</span>
                  </div>
                  <div class="col-6">
                        LINE ID
                        <input :disabled="!checkViewOrEdit" type="text" class="form-control dots-the-end" id="lineUserName"
                               v-model="lineUserName" @click="isDialogLine=true" readonly>
                  </div>

                </div>
              </div>
              <div class="col-8 pl-0">
                メモ
                <VueEditor :disabled="!checkViewOrEdit" v-model="dataEdit.memo_rich"
                  :editorToolbar="vue2EditorCustomize" @blur="validate('memo')" @keyup="validate('memo')" />
                <span class="text-danger">{{ errors.memo }}</span>
              </div>
              <div class="row">
                <div class="col-2 position-relative">
                  初回面談予定日時
                  <date-picker autocomplete="off" :disabled="!checkViewOrEdit" v-model="dataEdit.scheduled_first_support_datetime"
                    :config="option_scheduled_first_support_datetime"></date-picker>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  利用勧奨電話記録
                  <button v-if="checkViewOrEdit" @click="add_health_guidance_recommends"
                    class="btn btn-success pt-0 pb-0">
                    <i class="fa fa-plus" aria-hidden="true"></i> 追加
                  </button>
                </div>
              </div>
              <div v-for="(item, i) in health_guidance_recommends" :key="item.id + 'a'" class="row">
                <div class="col-2">
                  <span class="pl-20">架電日</span>
                  <div class="col-12 pt-0">
                    <div class="row">
                      <div class="col-4 pt-2 pr-0">
                        <i v-if="checkViewOrEdit" @click="remove_health_guidance_recommends(i)" class="btn-trash fa fa-trash mb-3"
                          aria-hidden="true">
                          <span class="font-weight-normal pl-5">{{i + 1}}</span>
                        </i>
                      </div>
                      <div class="col-8 pt-0 pr-0 position-relative">
                        <date-picker autocomplete="off" :disabled="!checkViewOrEdit" v-model="item.recommend_datetime" id="datetime"
                          name="date" :config="options" @input="validate_health_guidance_recommends('recommend_datetime',item)"></date-picker>
                        <span class="text-danger">{{item.messErrorRecommendDatetime}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2-n1">
                  連絡先
                  <select :disabled="!checkViewOrEdit" v-model="item.recommend_tel_type" class="form-select" @change="
                      validate_health_guidance_recommends(
                        'recommend_tel_type',
                        item
                      ), checkRecommendTelType(item)
                    ">
                    <option v-for="contact in contact_form" :key="contact.value + 'contact'" :value="contact.value">
                      {{ contact.text }}
                    </option>
                  </select>
                  <span class="text-danger">{{
                      item.messErrorRecommendTelType
                  }}</span>
                </div>
                <div class="col-2">
                  <input type="text" :disabled="!checkViewOrEdit || item.recommend_tel_type != '01'"
                    v-model="item.recommend_tel" @keyup="
                      validate_health_guidance_recommends('recommend_tel', item)
                    " class="form-control mt-5" />
                                      <span class="text-danger">{{
                        item.messErrRecommendTel
                    }}</span>
                </div>
                <div class="col-2-n1">
                  結果
                  <select :disabled="!checkViewOrEdit" v-model="item.recommend_result" class="form-select" @change="
                      validate_health_guidance_recommends(
                        'recommend_result',
                        item
                      )
                    ">
                    <option :value="null"> </option>
                    <option v-for="result in result" :key="result.text + 'result'" :value="result.value">
                      {{ result.text }}
                    </option>
                  </select>
                  <span class="text-danger">{{
                      item.messErrRecommendResult
                  }}</span>
                </div>
                <div class="col-2">
                  理由
                  <select :disabled="!checkViewOrEdit" v-model="item.recommend_result_reason" class="form-select"
                    @change="
                      validate_health_guidance_recommends(
                        'recommend_result_reason',
                        item
                      )
                    ">
                    <option :value="null"> </option>
                    <option v-for="reason in reason" :key="reason.text + 'reason'" :value="reason.value">
                      {{ reason.text }}
                    </option>
                  </select>
                  <!-- <span class="text-danger">{{
                      item.messErrRecommendResultReason
                  }}</span> -->
                </div>
                <div class="col-3">
                  聞き取り内容
                  <textarea class="form-control" :disabled="!checkViewOrEdit" v-model="item.recommend_memo" @keyup="
                      validate_health_guidance_recommends(
                        'recommend_memo',
                        item
                      )
                    " name="" rows="4"></textarea>
                                      <span class="text-danger">{{
                        item.messErrRecommendMemo
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade border" id="health-check-result" role="tabpanel"
            aria-labelledby="health-check-result-tab">
            <ul class="nav nav-pills pl-3 mt-5" id="pills-tab" role="tablist">
              <li class="nav-item border" role="presentation">
                <button class="nav-link active rounded-0" id="health-check-result-one-tab" data-bs-toggle="pill"
                  data-bs-target="#health-check-result-one" type="button" role="tab"
                  aria-controls="health-check-result-one" aria-selected="true">
                  健診基本情報
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link border rounded-0" id="health-check-result-two-tab" data-bs-toggle="pill"
                  data-bs-target="#health-check-result-two" type="button" role="tab"
                  aria-controls="health-check-result-two" aria-selected="false">
                  検査項目
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="health-check-result-one" role="tabpanel"
                aria-labelledby="health-check-result-one-tab">
                <!-- 健診基本情報 -->
                <div class="col-7 p-0 ml-3 mt-3 mb-5 border tab-health-check-result-one">
                  <table class="table">
                    <thead>
                      <th class="p-0 border-bottom">項目</th>
                      <th class="p-0 border-bottom">値</th>
                      </thead>
                      <tbody>
                      <tr class="horizontal-border-line" v-for="(item, index) in dataTabB.data_diagnostic_basic" :key="index + 'abc'">
                        <td class="font-size-lg">{{ item.healthexam_item_name_disp }}</td>
                        <td class="font-size-lg">{{ (item.healthexam_item_disp == "" || item.healthexam_item_disp == null) ? "ー" : item.healthexam_item_disp }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              <!-- 検査項目 -->
              <div class="tab-pane fade col-7 p-0 ml-3 mt-3 mb-5 border" id="health-check-result-two" role="tabpanel"
                     aria-labelledby="health-check-result-two-tab">
                  <table class="table">
                    <thead>
                    <th class="text-nowrap p-0 border-bottom width-280px">項目</th>
                    <th class="text-nowrap p-0 border-bottom">値</th>
                    <th class="text-nowrap p-0 pr-2 border-bottom width-90px">保健指導</th>
                    <th class="text-nowrap p-0 pr-2 border-bottom width-90px">受診勧奨</th>
                    <th class="text-nowrap p-0 pr-2 border-bottom width-90px">急ぎ治療</th>
                    </thead>
                    <tbody>
                    <tr class="horizontal-border-line" v-for="(item, index) in dataTabB.data_diagnostic_list" :key="index + 'search'" :class="{
                          color_level_10: item.judge_level == 10,
                          color_level_20: item.judge_level == 20,
                          color_level_30: item.judge_level == 30,
                        }">
                      <td class="font-size-lg">{{ item.healthexam_item_name_disp }}</td>
                      <td class="font-size-lg">
                        <span v-if="item.healthexam_item_disp?.length > 0">{{ item.healthexam_item_disp }}</span>
                        <span v-else>ー</span>
                      </td>
                      <td>
                      <span
                              v-if="dataTabB.data_diagnostic_search[item.healthexam_item_id] != undefined && dataTabB.data_diagnostic_search[item.healthexam_item_id][10] != undefined">
                        {{ get_healthexam_item_judgements(item.healthexam_item_id, 10) }}
                      </span>
                      </td>
                      <td>
                      <span
                              v-if="dataTabB.data_diagnostic_search[item.healthexam_item_id] != undefined && dataTabB.data_diagnostic_search[item.healthexam_item_id][10] != undefined">
                        {{ get_healthexam_item_judgements(item.healthexam_item_id, 20) }}
                      </span>
                      </td>
                      <td>
                      <span
                              v-if="dataTabB.data_diagnostic_search[item.healthexam_item_id] != undefined && dataTabB.data_diagnostic_search[item.healthexam_item_id][10] != undefined">
                        {{ get_healthexam_item_judgements(item.healthexam_item_id, 30) }}
                      </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div class="row">
          <div class="col-md-12 d-inline-flex justify-content-between">
            <button @click="hide" type="button" class="btn btn-secondary">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              キャンセル
            </button>
            <button v-if="checkViewOrEdit" @click="isShowDialog = true" type="button" class="btn btn-primary">
              <i class="fa fa-database" aria-hidden="true"></i>
              保存
            </button>
          </div>
        </div>
      </footer>
    </div>
    <Dialog :maxWidth="600" :isShow="isShowDialog">
      <v-card>
        <v-container>
          <v-card-title>
            保存
          </v-card-title>
          <div class="m-3">
            保存処理を実行します。よろしいですか？
          </div>
          <v-card-actions class="
              d-flex
              align-content-center
              justify-content-between
              mr-2
              ml-2
            ">
            <button type="button" class="btn btn-secondary" @click="isShowDialog = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="save()">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
      <Dialog :isShow="isDialogLine" persistent :maxWidth="600">
        <v-card>
          <v-container>
            <div class="row">
              <div class="col-12 d-flex">
                LINE ID
                <v-autocomplete
                    class="ml-3 col-7 container-autocomplete"
                    outlined
                    :disabled="!checkViewOrEdit"
                    v-model="lineUserAsync"
                    :items="itemsLineUser"
                    :search-input.sync="searchPatientLine"
                    hide-no-data
                    hide-details
                    no-filter
                    :loading="isLoading"
                    placeholder="ここに入力して検索"
                />
                <button v-if="checkViewOrEdit" style="height: 38px; margin-top: -5px;" class="ml-3 btn btn-info" @click="syncLineUser">
                  紐付け
                </button>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-2"
                   v-for="(item, index) in listContactLineOld"
                   :key="item.value + index + 'patient_line'"
              >
                <label
                    class="mr-8 ml-3 form-check-label"
                    :for="item.value"
                >
                  {{ item.label }}
                </label>
                <button @click="deleteContactLine(item.value)" type="button" class="btn btn-danger">紐付け解除</button>
              </div>
            </div>
            <v-card-actions class=" d-flex align-content-center justify-content-between mr-2 ml-2">
              <button
                  type="button"
                  class="btn btn-secondary"
                  @click="isDialogLine = false"
              >
                キャンセル
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
  </div>
</v-navigation-drawer>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import MedicalDesignatedSubjects from "@/services/api/MedicalDesignatedSubjects";
import { formEditMedicalDesignatedSubjects } from "../common/schemaValidation";
import Sheet from "@/components/common/Sheet";
import mixins from "@/mixins";
import { vue2EditorCustomize, TOAST_TIME_OUT, ENVS } from "@/common/constants";
import _ from "lodash";
import { VueEditor } from "vue2-editor";
import Dialog from "@/components/common/Dialog";
import DashboardService from "@/services/api/DashboardRepository";

export default {
    name: "detailImportDiagnosticData",
    mixins: [mixins],
    components: {
        Sheet,
        VueEditor,
        Dialog
    },
    props: {
        dataDetailId: {
            default: 0,
        },
        checkViewOrEdit: {
            default: false,
        },
        isShowNavigation: {
            default: "",
        },
    },
    data() {
        return {
            checkUpdateData: false,
            searchPatientLine: null,
            isLoading: false,
            isDialogLine: false,
            lineUserIdChoose: null,
            lineUserId: null,
            currentDataDetailId: null,
            listContactLine: [{
                value: 0,
                label: ""
            }],
            listContactLineOld: [{
                value: 0,
                label: "",
                patient_id: ""
            }],
            lineUserAsync: null,
            isShowDialog: false,
            lineUserName: null,
            vue2EditorCustomize: vue2EditorCustomize,
            dataTabA: [],
            dataTabAStatus: {
                codeSupportStatus: "ー",
                codeSupportExecStatus: "ー",
                codeSupportProgressStatus: null,
            },
            dataTabB: {
                data_diagnostic_basic: [],
                data_diagnostic_search: [],
                data_master_code: [],
                data_diagnostic_list: [],
            },
            health_guidance_recommends: [],
            date: null,
            contact_form: [],
            result: [],
            reason: [],
            dataEdit: {
                patient_tel_type_01: null,
                patient_tel_type_02: null,
                patient_tel_type_03: null,
                patient_tel_type_04: null,
                health_guidance_no_target: null,
                patient_tel_01: null,
                patient_tel_02: null,
                patient_tel_03: null,
                patient_tel_04: null,
                patient_mail_01: null,
                memo: null,
                memo_rich: null,
                scheduled_first_support_datetime: null,
            },
            errors: {
                health_guidance_no_target: null,
                patient_tel_01: null,
                patient_tel_02: null,
                patient_tel_03: null,
                patient_tel_04: null,
                patient_mail_01: null,
                memo: null,
                memo_rich: null,
                scheduled_first_support_datetime: null,
            },
            codeGuidanceDiv: [],
            listFamilyCode: [],
            code_gender: [],
            listContractCode: [],
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
        };
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        option_scheduled_first_support_datetime() {
            return {
                format: "YYYY-MM-DD HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        },

        itemsLineUser() {
            return this.listContactLine.map(contact => {
                return {
                    text: contact.label,
                    value: contact.value,
                }
            })
        }
    },
    watch: {
        dataDetailId() {
            this.getDetail(this.dataDetailId);
            this.getListContactLineOld();
        },
        checkViewOrEdit() {
            // console.log(this.checkViewOrEdit);
        },

        "dataTabA.codeSupportStatus"() {
            alert(this.dataTabA.codeSupportStatus);
        },
        "dataEdit.memo_rich"() {
            this.dataEdit.memo = jQuery(this.dataEdit.memo_rich).text()
            this.validate("memo")
        },
        dataTabA() {
            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        },
        isShowNavigation() {
            if (this.currentDataDetailId == this.dataDetailId) {
                this.getDetail(this.dataDetailId);
            } else {
                this.currentDataDetailId = this.dataDetailId;
            }
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        },
        searchPatientLine (val) {
            if(val == null || val == "" || (val && val.trim().length == 0)) {
                this.listContactLine = []
                return;
            }
            if(val && this.listContactLine.find(el => el.label === val)) return;
            if (this.isLoading) return
            this.isLoading = true
            DashboardService.getContactLine(val).then(response => {
                this.isLoading = false
                if (response && response.data && response.data.data) {
                    const {
                        data: {
                            data
                        }
                    } = response
                    this.listContactLine = data?.map(contactIt => {
                        return {
                            patient_id: contactIt.patient_id,
                            value: contactIt.line_user_id,
                            label: contactIt?.line_user_name + " - " + contactIt?.messages + (contactIt?.patient_id ? " ー　連携済み" : "")
                        }
                    })
                } else {
                    this.notifyError("LINEユーザー情報取得処理のエラーが発生しました。")
                }
            }).catch(error => {
                this.isLoading = false
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })

        },
    },
    created() {
        MedicalDesignatedSubjects.getGender().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.code_gender = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.code_gender.push({
                    text: value,
                    value: key,
                });
            }
        });

        MedicalDesignatedSubjects.getCodeMedicalIndication().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.listContractCode = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.listContractCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        MedicalDesignatedSubjects.getContactForm().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.contact_form = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.contact_form.push({
                    text: value,
                    value: key,
                });
            }
            this.contact_form = _.sortBy(this.contact_form, "value");
        });

        MedicalDesignatedSubjects.getResult().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.result = [];
            for (const[key, value] of Object.entries(res.data)) {
                this.result.push({
                    text: value,
                    value: key,
                });
            }
        });

        MedicalDesignatedSubjects.getReason().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.reason = [];
            for (const[key, value] of Object.entries(res.data)) {
                this.reason.push({
                    text: value,
                    value: key,
                });
            }
        });

        MedicalDesignatedSubjects.getMedicalIndication().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.codeGuidanceDiv = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.codeGuidanceDiv.push({
                    text: value,
                    value: key,
                });
            }
        });

        MedicalDesignatedSubjects.getCodeFamily().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            for (const [key, value] of Object.entries(res.data)) {
                this.listFamilyCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        this.currentDataDetailId = this.dataDetailId;
        this.getDetail(this.dataDetailId);

        DashboardService.getContactLine().then(response => {
            if (response && response.data && response.data.data) {
                const {
                    data: {
                        data
                    }
                } = response

                this.listContactLine = data?.map(contactIt => {
                    return {
                        patient_id: contactIt.patient_id,
                        value: contactIt.line_user_id,
                        label: contactIt?.line_user_name + " - " + contactIt?.messages + (contactIt?.patient_id ? " ー　連携済み" : "")
                    }
                })
                this.lineUserAsync = this.listContactLine.filter(x => x.patient_id == this.dataDetailId)[0]?.value;
            } else {
                this.notifyError("LINEユーザー情報取得処理のエラーが発生しました。")
            }
        }).catch(error => {
            this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
        })
        this.getListContactLineOld()
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;

        // this.$root.$on("Dashboard.LoadPages", () => {
        //     this.currentDataDetailId = this.dataDetailId;
        //     this.getDetail(this.dataDetailId);
        // });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        async getListContactLineOld() {
            await DashboardService.getContactLineOld(this.dataDetailId).then(response => {
                if (response && response.data && response.data.data) {
                    const {
                        data: {
                            data
                        }
                    } = response
                    this.listContactLineOld = data?.map(contactIt => {
                        return {
                            patient_id: contactIt.patient_id,
                            value: contactIt.line_user_id,
                            label: contactIt?.line_user_name + " - " + contactIt?.messages
                        }
                    })
	                this.lineUserName = this.listContactLineOld.map(contact => contact.label).join(", ");
                } else {
                    if (response?.errorCode != INTERNAL_SERVER_ERROR) {
                        this.notifyError("LINEユーザー情報取得処理のエラーが発生しました。")
                    }
                }
            }).catch(error => {
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })
        },
        async deleteContactLine(line_user_id) {
            await DashboardService.deleteContactLine(line_user_id).then(response => {
                if (response) {
                    this.getListContactLineOld()
                } else {
	                if (response?.errorCode != INTERNAL_SERVER_ERROR) {
		                this.notifyError("対象者のLINEアカウントへの紐付けが失敗しました。")
	                }
                }
            }).catch(error => {
                this.hideLoading()
                this.notifyError(`LINEユーザー情報取得処理のエラーが発生しました。内容：${error}`)
            })
        },
        choosePatientLine() {
            if(this.lineUserIdChoose) {
                this.lineUserId = this.lineUserIdChoose
                let idInput = "#" + this.lineUserIdChoose
                this.lineUserName = $(idInput).data("text")
            }
            this.isDialogLine = false
        },
        async syncLineUser() {
            const syncLineUserData = {
                line_user_id: this.lineUserAsync,
                patient_id: this.dataTabA.id
            }
            await DashboardService.syncLineContact(syncLineUserData).then(response => {
                if (response && response.data && response.data.data) {
                    this.isUserSyncLine = true;
                    this.notifySuccess("対象者のLINEアカウントに紐付けました。")
                    this.lineUserAsync = null
                    this.getListContactLineOld()
                } else {
	                if (response?.errorCode != INTERNAL_SERVER_ERROR) {
		                this.notifyError("対象者のLINEアカウントへの紐付けが失敗しました。")
	                }
                }
            }).catch(error => {
                this.notifyError(`対象者のLINEアカウントへの紐付けが失敗しました。原因は：${error}`)
            })
        },

        get_name_with_code_disp(healthexam_item_id) {
            if (healthexam_item_id && healthexam_item_id !== undefined) {
                const data = this.dataTabB.data_master_code[healthexam_item_id];
                if (data != undefined) {
                    return data.name_with_code_disp;
                }
            }
            return false;
        },
        check_healthexam_item_judge_method(healthexam_item_id) {
            if (healthexam_item_id && healthexam_item_id !== undefined) {
                const data = this.dataTabB.data_diagnostic_search[healthexam_item_id];
                if (data != undefined) {
                    return data;
                }
            }
            return false;
        },
        get_healthexam_item_judgements(healthexam_item_id, $judge_level) {
            var res = null;
            if (this.dataTabB.data_diagnostic_search[healthexam_item_id] != undefined && this.dataTabB.data_diagnostic_search[healthexam_item_id][$judge_level] != undefined) {
                const healthexam_item_judgement = this.dataTabB.data_diagnostic_search[healthexam_item_id][$judge_level];
                if (healthexam_item_judgement.healthexam_item_judge_method != "VAL_RANGE") {
                    return res;
                }

                if (healthexam_item_judgement.healthexam_item_data_type == "CODE") {
                    if (healthexam_item_judgement.judge_code_min != undefined) {
                        const data = this.dataTabB.data_master_code_code[healthexam_item_id][healthexam_item_judgement.judge_code_min];
                        if (data != undefined) {
                            res = data.name_with_code_disp + "≦";
                        }
                    }
                    if (healthexam_item_judgement.judge_code_max != undefined) {
                        const data = this.dataTabB.data_master_code_code[healthexam_item_id][healthexam_item_judgement.judge_code_max];
                        if (data != undefined) {
                            res = "≦" + data.name_with_code_disp;
                        }
                    }
                }

                if (this.dataTabB.data_diagnostic_search[healthexam_item_id][$judge_level].healthexam_item_data_type == "INT"
          || this.dataTabB.data_diagnostic_search[healthexam_item_id][$judge_level].healthexam_item_data_type == "DEC"
                ) {
                    if (healthexam_item_judgement.judge_val_min != undefined) {
                        res = healthexam_item_judgement.judge_val_min + "≦";
                    }
                    if (healthexam_item_judgement.judge_val_max != undefined) {
                        res = "≦" + healthexam_item_judgement.judge_val_max;
                    }
                }
            }

            return res;
        },
        exportPdf() {
            $(".rotate-loading").css("display", "inline-block");
            let req = new XMLHttpRequest();
            req.open("GET", `${ENVS.API_URL_BASE}/api/pdf-diagnostic-result/${this.dataDetailId}`, true);
            req.responseType = "blob";
            req.onprogress = function (e) {
                if (e.total) {
                    $(".rotate-loading").css("display", "none")
                }
            };
            req.onload = function () {
                let blob = req.response;
                let fileName = req.getResponseHeader("Name");
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = decodeURI(fileName).replaceAll("+", " ");
                link.click();
            };

            req.send();
        },
        validate_health_guidance_recommends(field, data) {
            switch (field) {
            case "recommend_tel":
                if (!data.recommend_tel.match(/^[0-9]+$/)) {
                    data.messErrRecommendTel =
              "連絡先 番号は半角数字で入力してください。";
                } else if (data.recommend_tel?.length > 15) {
                    data.messErrRecommendTel =
              "連絡先 番号は15文字以下で入力してください。";
                } else {
                    data.messErrRecommendTel = null;
                }
                if (!data.recommend_tel)
                    data.messErrRecommendTel = "連絡先 番号を入力してください。";
                break;
            case "recommend_memo":
                if (data.recommend_memo?.length > 500) {
                    data.messErrRecommendMemo =
              "聞き取り内容は500文字以下で入力してください。";
                } else {
                    data.messErrRecommendMemo = null;
                }
                break;

                // case "recommend_result_reason":
                //     if (data.recommend_result_reason) {
                //         data.messErrRecommendResultReason = null;
                //     } else {
                //         data.messErrRecommendResultReason = "理由を入力してください。";
                //     }
                //     break;

            case "recommend_result":
                if (data.recommend_result) {
                    data.messErrRecommendResult = null;
                } else {
                    data.messErrRecommendResult = "結果を入力してください。";
                }
                break;
            case "recommend_tel_type":
                if (data.recommend_tel_type) {
                    data.messErrorRecommendTelType = null;
                } else {
                    data.messErrorRecommendTelType = "連絡先 種別を入力してください。";
                }
                break;

            case "recommend_datetime":
                if (data.recommend_datetime) {
                    data.messErrorRecommendDatetime = null;
                } else {
                    data.messErrorRecommendDatetime = "架電日を入力してください。";
                }
                break;
            default:
                break;
            }
        },
        validate(field) {
            formEditMedicalDesignatedSubjects
                .validateAt(field, this.dataEdit)
                .then(() => {
                    this.errors[field] = "";
                })
                .catch((err) => {
                    this.errors[field] = err.message;
                });
        },
        formatDate(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },
        add_health_guidance_recommends() {
            this.health_guidance_recommends.push({
                id: null,
                patient_id: null,
                recommend_datetime: null,
                recommend_memo: null,
                recommend_result: null,
                recommend_result_reason: null,
                recommend_tel: null,
                recommend_tel_type: this.contact_form[0]?.value,
                messErrRecommendTel: null,
                messErrRecommendMemo: null,
                messErrorRecommendDatetime: null,
                messErrorRecommendTelType: null,
                messErrRecommendResult: null,
                // messErrRecommendResultReason: null,
            });
            this.health_guidance_recommends = _.orderBy(this.health_guidance_recommends, "id", "asc").reverse();
        },
        remove_health_guidance_recommends(index) {
            this.health_guidance_recommends.splice(index, 1);
        },
        checkRecommendTelType(item) {
            if (item.recommend_tel != 10 && item.recommend_tel != 20 && item.recommend_tel != 30 && item.recommend_tel != 99) {
                item.recommend_tel = null;
            }
            if (item.recommend_tel_type == 10) {
                item.recommend_tel = this.dataEdit.patient_tel_01;
            }
            if (item.recommend_tel_type == 20) {
                item.recommend_tel = this.dataEdit.patient_tel_02;
            }
            if (item.recommend_tel_type == 30) {
                item.recommend_tel = this.dataEdit.patient_tel_03;
            }
            if (item.recommend_tel_type == 99) {
                item.recommend_tel = this.dataEdit.patient_tel_04;
            }

            this.validate_health_guidance_recommends("recommend_tel", item);
        },

        getDetail(detailId) {
            if (detailId === null) {
                return;
            }

            MedicalDesignatedSubjects.getDetailTabA(detailId).then((res) => {
                const { data, status, error } = res;
                this.setIsLoading(false);
                if (status !== SUCCESS) {
	                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                    return;
                }
                this.dataTabA = data.data;
                this.dataTabA.health_guidance_div = this.getHealthGuidanceDiv(this.dataTabA.health_guidance_div);
                this.dataTabA.person_familiy_div = this.getFamilyCode(this.dataTabA.person_familiy_div);
                this.dataTabA.health_guidance_contract_div = this.getHealthGuidanceContract(this.dataTabA.health_guidance_contract_div);
                this.dataTabA.patient_gender = this.getGender(this.dataTabA.patient_gender);

                const health_support_flow_segments = data.data.health_guidance_receptions[0]?.health_support_flows[0]?.get_health_support_flow_segments
                const health_support_flows = data.data.health_guidance_receptions[0]?.health_support_flows[0];

                if (health_support_flows != undefined) {
                    MedicalDesignatedSubjects.getCodeSupportStatus().then((res) => {
                        for (const [key, value] of Object.entries(res.data)) {
                            if (health_support_flows.support_flow_status == key) {
                                this.dataTabAStatus.codeSupportStatus = value;
                            }
                        }
                    });
                }

                if (health_support_flow_segments != undefined) {
                    let dataHealthFlowSegment = _.sortBy(
                        health_support_flow_segments,
                        "support_flow_seq"
                    );
                    var itemHealthFlowSegment = null;
                    var count = 0;
                    for (let i = 0; i < dataHealthFlowSegment.length; i++) {
                        if (dataHealthFlowSegment[i].support_exec_div == 20) {
                            count++;
                        }

                        if (
                            dataHealthFlowSegment[i].support_progress_status == 10 ||
                            dataHealthFlowSegment[i].support_progress_status == 20 ||
                            dataHealthFlowSegment[i].support_progress_status == 30
                        ) {
                            itemHealthFlowSegment = dataHealthFlowSegment[i];
                            break;
                        }
                    }

                    if (health_support_flows.support_flow_status != 30 && 
                        health_support_flows.support_flow_status != 40 && 
                        health_support_flows.support_flow_status != 50) {
                        MedicalDesignatedSubjects.getCodeSupportExecStatus().then(
                            (res) => {
                                for (const [key, value] of Object.entries(res.data)) {
                                    if (itemHealthFlowSegment.support_exec_div == key) {
                                        let checkCount = count != 0 ? ` (${count} 回目) ` : " ";
                                        this.dataTabAStatus.codeSupportExecStatus = value;
                                        if (key == 20) {
                                            this.dataTabAStatus.codeSupportExecStatus = value + checkCount;
                                        }
                                    }
                                }
                            }
                        );
                        MedicalDesignatedSubjects.getCodeSupportProgressStatus().then(
                            (res) => {
                                for (const [key, value] of Object.entries(res.data)) {
                                    if (itemHealthFlowSegment.support_progress_status == key) {
                                        this.dataTabAStatus.codeSupportProgressStatus = value;
                                    }
                                }
                            }
                        );
                    }
                }

                this.health_guidance_recommends = [];
                if (data.data.health_guidance_recommends?.length > 0) {
                    this.health_guidance_recommends = [];
                    data.data.health_guidance_recommends.map((x) => {
                        this.health_guidance_recommends.push({
                            id: x.id,
                            patient_id: x.patient_id,
                            recommend_datetime: x.recommend_datetime,
                            recommend_memo: x.recommend_memo,
                            recommend_result: x.recommend_result,
                            recommend_result_reason: x.recommend_result_reason,
                            recommend_tel: x.recommend_tel,
                            recommend_tel_type: x.recommend_tel_type,
                            messErrRecommendTel: null,
                            messErrRecommendMemo: null,
                            messErrorRecommendDatetime: null,
                            messErrorRecommendTelType: null,
                            messErrRecommendResult: null,
                            // messErrRecommendResultReason: null,
                        });
                    });
                }
                this.dataEdit.health_guidance_no_target = data.data.health_guidance_no_target;

                this.dataEdit.patient_tel_type_01 = data.data.patient_tel_type_01;
                this.dataEdit.patient_tel_type_02 = data.data.patient_tel_type_02;
                this.dataEdit.patient_tel_type_03 = data.data.patient_tel_type_03;
                this.dataEdit.patient_tel_type_04 = data.data.patient_tel_type_04;
                this.dataEdit.patient_tel_01 = data.data.patient_tel_01;
                this.dataEdit.patient_tel_02 = data.data.patient_tel_02;
                this.dataEdit.patient_tel_03 = data.data.patient_tel_03;
                this.dataEdit.patient_tel_04 = data.data.patient_tel_04;
                this.dataEdit.patient_mail_01 = data.data.patient_mail_01;
                this.dataEdit.memo = data.data.memo;
                this.dataEdit.memo_rich = data.data.memo_rich;
                this.dataEdit.scheduled_first_support_datetime = data.data.scheduled_first_support_datetime;
            });

            // 健診結果(検査項目)
            MedicalDesignatedSubjects.getDetailTabB(detailId).then((res2) => {
                const { data, status } = res2;
                this.setIsLoading(false);
                if (status !== SUCCESS) return;
                this.dataTabB.data_diagnostic_basic = data.data.diagnostic_basic;
                this.dataTabB.data_diagnostic_search = data.data.diagnostic_search;
                this.dataTabB.data_master_code = data.data.master_code;
                this.dataTabB.data_master_code_code = data.data.master_code_code;
                this.dataTabB.data_diagnostic_list = this.filterHasValueHealthexamResults(data.data.diagnostic_list);
            });
        },

        /**
         * 健診結果値が存在する項目のみでフィルタ
         */
        filterHasValueHealthexamResults(list) {
            const self = this;
            return list?.filter(function(x, index, array) {
                return self.hasHealthexamResultValue(x.healthexam_item_disp);
            });
        },
        /**
         * 健診結果値が存在する場合true
         */
        hasHealthexamResultValue(v) {
            const trimedVal = v?.trim();
            return trimedVal && !trimedVal.startsWith("ー");
        },

        getHealthGuidanceContract(health_guidance_contract_div) {
            if (
                health_guidance_contract_div &&
        health_guidance_contract_div != undefined
            ) {
                let contractCode = this.listContractCode.filter(
                    (x) => x.value == health_guidance_contract_div
                );
                if (contractCode?.length > 0) {
                    return contractCode[0].text;
                }
            }
            return null;
        },
        getFamilyCode(person_familiy_div) {
            if (person_familiy_div && person_familiy_div != undefined) {
                let familyCode = this.listFamilyCode.filter(
                    (x) => x.value == person_familiy_div
                );
                if (familyCode?.length > 0) {
                    return familyCode[0].text;
                }
            }
            return null;
        },
        getGender(gender) {
            if (gender && gender != undefined) {
                let genderCode = this.code_gender.filter(
                    (x) => x.value == gender
                );
                if (genderCode?.length > 0) {
                    return genderCode[0].text;
                }
            }
            return null;
        },
        getHealthGuidanceDiv(code) {
            const value = this.codeGuidanceDiv.filter((x) => x.value == code);
            if (value?.length > 0) {
                return value[0].text;
            }
            return null;
        },
        hide() {
            $("#eligible-person-information-tab").click()
            this.hiddenAnimation = true;
            this.health_guidance_recommends = [],
            this.errors = {
                health_guidance_no_target: null,
                patient_tel_01: null,
                patient_tel_02: null,
                patient_tel_03: null,
                patient_tel_04: null,
                patient_mail_01: null,
                memo: null,
                scheduled_first_support_datetime: null,
            };
            // 右スライドパネルを隠す際に詳細を読み込むのは意味がわからないので、コメントアウト.
            //this.getDetail(this.dataDetailId);
	          this.lineUserName = null;

            if (this.checkUpdateData) {
                this.checkUpdateData = false;
                this.$emit("reload", "", "update");
            }

            this.$store.commit("hideSlide");
        },
        async save() {
            this.isShowDialog = false;
            formEditMedicalDesignatedSubjects
                .validate(this.dataEdit, { abortEarly: false })
                .then(() => {
                    this.errors = {};
                    this.health_guidance_recommends.map((x) => {
                        if (!x.recommend_tel)
                            x.messErrRecommendTel = "連絡先 番号を入力してください。";
                        if (!x.recommend_datetime)
                            x.messErrorRecommendDatetime = "架電日を入力してください。";
                        if (!x.recommend_tel_type)
                            x.messErrorRecommendTelType = "連絡先 種別を入力してください。";
                        if (!x.recommend_result)
                            x.messErrRecommendResult = "結果を入力してください。";
                        // if (!x.recommend_result_reason)
                        //     x.messErrRecommendResultReason = "理由を入力してください。";
                    });

                    let checkValidate = this.health_guidance_recommends.filter(
                        (x) =>
                            x.messErrRecommendMemo ||
                            x.messErrRecommendTel ||
                            x.messErrorRecommendDatetime ||
                            x.messErrorRecommendTelType ||
                            x.messErrRecommendResult
                        // || x.messErrRecommendResultReason
                    );

                    if (checkValidate?.length == 0) {
                        this.setIsLoading(true);
                        this.dataEdit.health_guidance_recommends = this.health_guidance_recommends;
                        MedicalDesignatedSubjects.update(
                            this.dataDetailId,
                            this.dataEdit
                        ).then((res) => {
	                        this.isShowDialog = false;
	                        this.setIsLoading(false);
	                        const { status, error } = res;
	                        this.setIsLoading(false);
	                        if (status !== SUCCESS) {
		                        this.$toast.error(`[${error?.messageID}] ${error?.message}`);
		                        return;
	                        }
                            this.checkUpdateData = true;
                            this.$toast.open({
                                message: "保健指導対象者情報の登録が完了しました。",
                                type: "success",
                            });
                            this.getDetail(this.dataDetailId);
                        });
                    }
                })
                .catch((err) => {
                    this.isShowDialog = false;
                    this.setIsLoading(false);
                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },
    },
};
</script>
<style lang="sass" scoped>
  @import "../styles/common"

  .horizontal-border-line
    border-bottom: solid 1px
    border-color: #dee2e6
    border-bottom-width: 1px
  .font-size-lg
    font-size: 130%
  .width-90px
    width: 90px
    max-width: 90px
    min-width: 90px
  .min-width-120px
    width: 120px
    min-width: 120px
    max-width: 120px
  .width-180px
    width: 180px
    max-width: 180px
    min-width: 180px
  .width-200px
    width: 200px
    max-width: 200px
    min-width: 200px
  .width-250px
    width: 250px
    max-width: 250px
    min-width: 250px
  .width-280px
    width: 280px
    max-width: 280px
    min-width: 280px
  .rotate-loading
    display: none
    animation: rotate 1.5s linear infinite
  @keyframes rotate
    100%
      transform: rotate(360deg)
  .sidebar-show
    animation: show 0.5s ease forwards

  .sidebar-hidden
    animation: hidden 0.5s ease forwards

  @media (max-width: 1025px)
    .navigation
      transform: translate(0%) !important
    .v-navigation-drawer--close
      visibility: unset

  @keyframes hidden
    from
      width: var(widthAnimation)
    to
      width: 0

  @keyframes show
    from
      width: 0
    to
      width: var(widthAnimation)

  #eligible-person-information
    @include responsive-height(XS, calc(100vh - 25vh), calc(100vh - 25vh), scroll, hidden)
    @include responsive-height(XL, calc(100vh - 35vh), calc(100vh - 35vh), scroll, hidden)
    @include responsive-height(LG, calc(100vh - 40vh), calc(100vh - 40vh), scroll, hidden)

  .tab-health-check-result-one, #health-check-result-two
    @include responsive-height(XS, calc(100vh - 30vh), calc(100vh - 30vh), scroll, hidden)
    @include responsive-height(XL, calc(100vh - 45vh), calc(100vh - 45vh), scroll, hidden)
    @include responsive-height(LG, calc(100vh - 50vh), calc(100vh - 50vh), scroll, hidden)

  #detail-import-diagnostic-data
    @include responsive-height(XS, calc(100vh - 15vh), calc(100vh - 15vh), hidden, hidden)
    @include responsive-height(XL, calc(100vh - 20vh), calc(100vh - 20vh), hidden, hidden)
    @include responsive-height(LG, calc(100vh - 25vh), calc(100vh - 25vh), hidden, hidden)
	  
  .dots-the-end
    text-overflow: ellipsis
</style>
<style lang="sass">
  .container-autocomplete.v-text-field--outlined
      .v-input__control
          .v-input__slot
              min-height: 10px
  .container-autocomplete.v-text-field--enclosed
      .v-input__append-inner
          margin-top: 2px
  .btn-trash
      font-size: 1.5rem
      &:hover
          cursor: pointer
      span
          font-size: 0.8rem
          &:hover
              cursor: default
  .pl-20
       padding-left: 5.5rem
  .col-2-n1
       flex: 0 0 auto
       width: 12%
       padding: 12px
  #pills-tabContent
       overflow-hidden: hidden
</style>
